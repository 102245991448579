var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "wrapper__card" }, [
        _vm._m(0),
        _c("div", { staticClass: "wrapper__card__body" }, [
          _c("div", { staticClass: "wrapper__card__body__left" }, [
            _c("div", { staticClass: "card-key" }, [
              _c("strong", [_vm._v("Sua chave de acesso:")]),
              _c("div", { staticClass: "card-key__line mt-2" }, [
                _c("span", [_vm._v(" " + _vm._s(_vm.token || "-") + " ")]),
                _c("img", { attrs: { src: "/icons/copy-icon.svg" } }),
              ]),
            ]),
            _vm._m(1),
            _c("div", { staticClass: "card-parameters" }, [
              _c("span", { staticClass: "title-item" }, [_vm._v("Parâmetros")]),
              _c(
                "div",
                { staticClass: "card-parameters__table" },
                [
                  _c("z-table", {
                    staticClass: "table",
                    attrs: { headers: _vm.headers, items: _vm.tableItems },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "wrapper__card__body__right" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "tabs" },
              [
                _c("z-tabs", {
                  ref: "tabs",
                  attrs: { android: "", tabs: _vm.tabs },
                  scopedSlots: _vm._u([
                    {
                      key: "terceira",
                      fn: function () {
                        return [
                          _vm.objectMounted != null
                            ? _c(
                                "CodeHighlight",
                                { attrs: { language: "javascript" } },
                                [
                                  _c("pre", [
                                    _c("code", [
                                      _vm._v(
                                        "\ncurl --location 'https://datatoact-back.eye.zooxsmart.com/api/exportData' \\\n--header 'Content-Type: application/json' \\\n--header 'token: " +
                                          _vm._s(_vm.token) +
                                          "' \\\n--data-raw '\n     " +
                                          _vm._s(_vm.objectMounted) +
                                          "\n'\n"
                                      ),
                                    ]),
                                    _vm._v("\n                    "),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrapper__card__header" }, [
      _c("div", { staticClass: "left" }, [
        _c("span", [_vm._v("Extração de dados")]),
      ]),
      _c("div", { staticClass: "right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-resource" }, [
      _c("span", { staticClass: "title-item" }, [_vm._v(" Resource URL ")]),
      _c("span", { staticClass: "link-resource" }, [
        _vm._v(" https://datatoact-back.eye.zooxsmart.com/api/exportData "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "how-test" }, [
      _c("span", { staticClass: "title-item" }, [_vm._v("Como testar")]),
      _c("p", [
        _vm._v(
          " Para extrair dados da API é necessário utilizar algum cliente HTTP, seja gráfico (Postman, Insomnia...) ou programaticamente usando tecnoligias como CURL, Node.js e Python. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }